<template>
    <div class="container">
        <div class="checkAuthWrap">
            <p class="title">账号检查</p>
            <div class="tips">在进行广告投放前，你需要完成账号的基础检查，我们将通过以下广告账号和身份进行广告发布，请确认</div>
            <div class="authForm">
                <el-form ref="form" :model="authForm" label-width="200px">
                    <el-form-item label="域名：">
                        <el-input v-model="authForm.region" placeholder="请输入域名"></el-input>
                    </el-form-item>
                    <el-form-item label="广告账户：">
                        <el-select v-model="authForm.region" placeholder="请选择广告账号">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="商务管理平台（Bussiness Manager）：">
                        <el-select v-model="authForm.region" placeholder="请选择商务管理平台">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label="Shopify 店铺：">
                        <el-select v-model="authForm.region" placeholder="请选择Shopify 店铺">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                        <p class="msg">店铺域名与当前广告账户开户域名不相符 
                            <el-popover  placement="right" title=""  width="400" trigger="hover" content="风险提醒：facebook规定，推广网址必须是开户时填写的域名，推广网址非开户域名会导致facebook投放失败或封号">
                                <i class="el-icon-info"  slot="reference"></i>
                            </el-popover>
                        </p>
                    </el-form-item> -->
                    <el-form-item label="像素（Pixel）：">
                        <el-select v-model="authForm.region" placeholder="请选择像素（Pixel）">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                        <p class="msg">你所选的Pixel未在当前店铺安装，请检查确认后再投放</p>
                    </el-form-item>
                    <el-form-item label="广告目标：">
                        <el-radio-group v-model="authForm.target">
                            <el-radio :label="3">获取新用户</el-radio>
                            <el-radio :label="6">促进转化</el-radio>
                        </el-radio-group>
                        <p class="msg">用网站中最受欢迎的商品生成创意，带来更多曝光，吸引相关兴趣的用户</p>
                        <p class="msg">当前网站用户还比较少，重要的是让更多用户开始访问你的网站，了解你的产品。</p> 
                    </el-form-item>
                </el-form>
                
            </div>
            <div class="btn">
                <p>
                    <el-button type="primary" @click="next">继续</el-button>
                </p>
                <p>
                    <el-button type="text" @click="back">返回广告管理</el-button>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                authForm:{}
            }
        },
        methods:{
            next(){
                this.$router.push({name:'createFastAd'})
            },
            back(){
                this.$router.push({name:'adManagement'})
                this.$store.dispatch('app/openSideBar')
            },
        },
        mounted() {
            this.$store.dispatch('app/closeSideBar');
        },
        beforeDestroy() {
            this.$store.dispatch('app/openSideBar')
        }
    }
</script>
<style lang="scss" scope>
.container{
    width: 100%;
    height: calc(100vh - 90px);
    background: #fff;
}
    .checkAuthWrap{
        width: 800px;
        margin: auto;
        text-align: center;
        margin-top: 100px;
        .title{
            font-size: 28px;
            margin: 20px;
        }
        .el-form-item__content{
            text-align: left;
        }
        .btn{
            margin-top: 50px;
            p{
                margin: 15px;
                .el-button{
                    width: 160px;
                }
            }
        }
        .authForm{
            margin: 20px auto;
            width: 90%;
            padding: 30px;
            border: 1px solid #ddd;
            .el-select{
                width: 100%;
            }
            .msg{
                text-align: left;
                color: #E6A23C;
                line-height: 1.8;
            }
            
        }
    }
</style>